import { ProjectWithInterviewCount } from "app-types";
import React from "react";
import { Label, SizesEnum } from "ui";
import { useAppSelector } from "../../../hooks/hook";
import { AssessmentEditor } from "../../assessments/assessmentEditor";
import { selectAssessmentById } from "../../assessments/assessmentsSlice";

interface InterviewAssessmentSettingsSectionProps {
  project: ProjectWithInterviewCount;
}

export const InterviewAssessmentSettingsSection: React.FC<
  InterviewAssessmentSettingsSectionProps
> = ({ project }) => {
  const assessment = useAppSelector(
    selectAssessmentById(project.assessment_id)
  );

  if (!assessment) {
    return null;
  }

  return (
    <div>
      <Label size={SizesEnum.LARGE}>AI Assessment</Label>
      <div className="mt-2 text-sm text-gray-600">
        Completed interviews will be automatically assessed using the criteria
        below, allowing you to quickly find candidates that meet the key job
        requirements.
      </div>
      <div className="mt-4 mb-4">
        <AssessmentEditor assessment={assessment} />
      </div>
    </div>
  );
};
