import {
  LanguageIcon,
  PencilIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  Assessment,
  CANDIDATE_ALIGN_FIELD_NAME,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  CustomFieldResourceTypesEnum,
  CustomFieldTypesEnum,
  getSpecialAssessmentField,
  isSpecialAssessmentFieldName,
  sortAssessmentFields,
  SpecialAssessmentFieldDetails,
  SpecialAssessmentFieldNamesEnum,
} from "app-types";
import React, { useState } from "react";
import { Button, ButtonVariantsEnum, SizesEnum } from "ui";
import { useAppDispatch } from "../../hooks/hook";
import { SaveAndCancelButtons } from "../projects/saveAndCancelButtons";
import {
  ASSESSMENT_FIELD_NAME_MAX_LENGTH,
  ASSESSMENT_FIELD_NAME_MIN_LENGTH,
  AssessmentEditorRow,
} from "./assessmentEditorRow";
import { updateAssessment } from "./assessmentsSlice";

const MAX_FIELDS = 10;

interface AssessmentEditorProps {
  assessment: Assessment;
}

export const AssessmentEditor: React.FC<AssessmentEditorProps> = ({
  assessment,
}) => {
  const sortedOriginalFields = sortAssessmentFields(
    assessment.custom_field_definitions || []
  );

  const dispatch = useAppDispatch();
  const [fields, setFields] =
    useState<(CustomFieldDefinition | CustomFieldDefinitionInsertClient)[]>(
      sortedOriginalFields
    );
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const getErrorMessageForCriteriaValue = (value: string): string => {
    if (value.length < ASSESSMENT_FIELD_NAME_MIN_LENGTH) {
      return `Criteria must be at least ${ASSESSMENT_FIELD_NAME_MIN_LENGTH} characters long`;
    }
    if (value.length > ASSESSMENT_FIELD_NAME_MAX_LENGTH) {
      return `Criteria must not exceed ${ASSESSMENT_FIELD_NAME_MAX_LENGTH} characters`;
    }

    // Don't allow the user to rename the field to one of the reserved field names
    if (isSpecialAssessmentFieldName(value)) {
      return "This field name is reserved, please choose another one";
    }

    return "";
  };

  const onAddField = (specialFieldName?: SpecialAssessmentFieldNamesEnum) => {
    const specialFieldDetails = specialFieldName
      ? SpecialAssessmentFieldDetails[specialFieldName]
      : null;

    if (fields.length < MAX_FIELDS) {
      const newField: CustomFieldDefinitionInsertClient = {
        display_name: specialFieldDetails?.display_name || "",
        field_name: specialFieldName || "",
        field_type:
          specialFieldDetails?.field_type || CustomFieldTypesEnum.BOOLEAN,
        provider: "alpharun",
        resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
      };

      // Add special fields to the top of the list
      setFields(
        specialFieldDetails ? [newField, ...fields] : [...fields, newField]
      );

      setHasMadeChanges(true);
    }
  };

  const onRemoveField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
    setFieldErrors(fieldErrors.filter((_, i) => i !== index));
    setHasMadeChanges(true);
  };

  const onRenameField = (index: number, newName: string) => {
    // When we rename a field we're effectively creating a new custom field definition
    setFields(
      fields.map((field, i) =>
        i === index
          ? {
              display_name: newName,
              field_name: newName,
              resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
              provider: "alpharun",
              field_type: field.field_type,
            }
          : field
      )
    );
    setHasMadeChanges(true);
  };

  const validateFieldOnBlur = (index: number, value: string) => {
    const error = getErrorMessageForCriteriaValue(value);
    setFieldErrors((prev) => {
      const newErrors = [...prev];
      newErrors[index] = error;
      return newErrors;
    });
  };

  const validateAllFields = (
    fieldsToValidate: (
      | CustomFieldDefinition
      | CustomFieldDefinitionInsertClient
    )[]
  ) => {
    const newErrors = fieldsToValidate.map((field) =>
      getErrorMessageForCriteriaValue(field.display_name)
    );
    setFieldErrors(newErrors);
    return newErrors.every((error) => error === "");
  };

  const onClickSave = () => {
    const nonEmptyFields = fields.filter(
      (field) => field.display_name.trim() !== ""
    );

    if (validateAllFields(nonEmptyFields)) {
      dispatch(
        updateAssessment({
          assessmentId: assessment.id,
          changes: {
            custom_field_definitions: nonEmptyFields,
          },
        })
      );
      setFields(nonEmptyFields);
      setIsEditMode(false);
      setHasMadeChanges(false);
    }
  };

  const onCancel = () => {
    setFields(sortedOriginalFields);
    setFieldErrors([]);
    setIsEditMode(false);
  };

  const renderTableRows = () => {
    if (fields.length === 0) {
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={3}
          >
            No assessment criteria added yet
          </td>
        </tr>
      );
    }

    return fields.map((field, index) => (
      <AssessmentEditorRow
        key={`assessment-field-${index}`}
        field={field}
        isEditMode={isEditMode}
        onRenameField={(newName: string) => onRenameField(index, newName)}
        onBlur={(value: string) => validateFieldOnBlur(index, value)}
        onRemoveField={() => onRemoveField(index)}
        fieldError={fieldErrors[index]}
      />
    ));
  };

  return (
    <div className="p-[1px]">
      <div className="ring-1 ring-black ring-opacity-10 rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="w-[calc(100%-130px)] px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Criteria
              </th>
              <th
                scope="col"
                className="w-[130px] px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {renderTableRows()}
          </tbody>
        </table>
      </div>
      {isEditMode && (
        <div className="mt-2 flex flex-row space-x-2">
          <Button
            variant={ButtonVariantsEnum.Secondary}
            icon={<PlusIcon className="w-4 h-4 mr-2" />}
            onClick={() => onAddField()}
            size={SizesEnum.MEDIUM}
            label="Add criteria"
            isDisabled={fields.length >= MAX_FIELDS}
          />
          {getSpecialAssessmentField(
            fields,
            SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH
          ) ? null : (
            <Button
              variant={ButtonVariantsEnum.Secondary}
              icon={<LanguageIcon className="w-4 h-4 mr-2" />}
              onClick={() => {
                onAddField(
                  SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH
                );
              }}
              size={SizesEnum.MEDIUM}
              label="Add CEFR language assessment"
              isDisabled={fields.length >= MAX_FIELDS}
            />
          )}
          {fields.find(
            (f) => f.display_name === CANDIDATE_ALIGN_FIELD_NAME
          ) ? null : (
            <Button
              variant={ButtonVariantsEnum.Secondary}
              icon={<UserIcon className="w-4 h-4 mr-2" />}
              onClick={() => {
                const newField: CustomFieldDefinitionInsertClient = {
                  display_name: CANDIDATE_ALIGN_FIELD_NAME,
                  field_name: CANDIDATE_ALIGN_FIELD_NAME,
                  field_type: CustomFieldTypesEnum.BOOLEAN,
                  provider: "alpharun",
                  resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
                };

                // Add special fields to the top of the list
                setFields([newField, ...fields]);

                setHasMadeChanges(true);
              }}
              size={SizesEnum.MEDIUM}
              label="Add candidate/job alignment rating"
            />
          )}
        </div>
      )}
      <div className="mt-4">
        {isEditMode ? (
          <SaveAndCancelButtons
            onSave={onClickSave}
            onCancel={onCancel}
            isSaveDisabled={!hasMadeChanges}
          />
        ) : (
          <Button
            variant={ButtonVariantsEnum.Secondary}
            icon={<PencilIcon className="w-4 h-4 mr-2" />}
            label="Edit criteria"
            onClick={() => {
              setIsEditMode(true);
              onAddField();
            }}
            size={SizesEnum.MEDIUM}
          />
        )}
      </div>
    </div>
  );
};
