import { LanguageIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import {
  CANDIDATE_ALIGN_FIELD_NAME,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  CustomFieldTypesEnum,
  SpecialAssessmentFieldNamesEnum,
} from "app-types";
import React from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  Input,
  Pill,
  PillColorsEnum,
  SizesEnum,
} from "ui";

interface AssessmentEditorRowProps {
  field: CustomFieldDefinition | CustomFieldDefinitionInsertClient;
  isEditMode: boolean;
  onRenameField: (newName: string) => void;
  onBlur: (value: string) => void;
  onRemoveField: () => void;
  fieldError: string;
}

export const ASSESSMENT_FIELD_NAME_MIN_LENGTH = 10;
export const ASSESSMENT_FIELD_NAME_MAX_LENGTH = 200;

export const AssessmentEditorRow: React.FC<AssessmentEditorRowProps> = ({
  field,
  isEditMode,
  onRenameField,
  onBlur,
  onRemoveField,
  fieldError,
}) => {
  const renderFieldContent = () => {
    if (
      field.field_name ===
        SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH &&
      !fieldError
    ) {
      return (
        <div className="flex items-center space-x-2">
          <LanguageIcon className="w-4 h-4 mr-2" />
          CEFR English Level Assessment
        </div>
      );
    }

    if (field.display_name === CANDIDATE_ALIGN_FIELD_NAME && !fieldError) {
      return (
        <div className="flex items-center space-x-2">
          <UserIcon className="w-4 h-4 mr-2" />
          Candidate/Job Alignment
        </div>
      );
    }

    if (isEditMode) {
      return (
        <div>
          <Input
            value={field.display_name}
            onChange={(e) => onRenameField(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
            placeholder="Has 2+ years of customer service experience"
            errorDescription={fieldError}
            maxLength={ASSESSMENT_FIELD_NAME_MAX_LENGTH}
          />
        </div>
      );
    }

    return field.display_name;
  };

  const renderFieldType = () => {
    if (field.field_type === CustomFieldTypesEnum.TEXT) {
      return (
        <Pill
          label={"Text"}
          color={PillColorsEnum.GREY}
          size={SizesEnum.SMALL}
        />
      );
    }

    return (
      <Pill
        label={"Yes / No"}
        color={PillColorsEnum.GREY}
        size={SizesEnum.SMALL}
      />
    );
  };

  return (
    <tr>
      <td className="px-3 py-2 text-sm text-gray-900">
        {renderFieldContent()}
      </td>
      <td className="px-3 py-2 text-sm text-gray-500">
        <div className="flex items-center justify-between w-full">
          {renderFieldType()}
          {isEditMode && (
            <IconButton
              variant={ButtonVariantsEnum.Secondary}
              icon={<TrashIcon className="w-4 h-4 text-red-500" />}
              onClick={onRemoveField}
            />
          )}
        </div>
      </td>
    </tr>
  );
};
