import { PhoneIcon } from "@heroicons/react/24/solid";
import {
  InterviewCallMetadata,
  LoadingStatesEnum,
  TranscriptFragment,
} from "app-types";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Select, TextSkeleton } from "ui";
import { TranscriptFragmentText } from "../../insights/transcriptFragmentText";

const STICKY_HEADER_HEIGHT = 80;

interface InterviewTranscriptProps {
  loadingState: LoadingStatesEnum;
  transcriptFragments: TranscriptFragment[];
  highlightedTranscriptFragment: string | null;
  onClickShare: (fragment: TranscriptFragment) => void;
  onHighlightFragment: (fragmentId: string | null) => void;
  calls: InterviewCallMetadata[];
  recordingIdToUrl: Record<string, string>;
}

const speedOptions = [
  { name: "1x speed", value: 1 },
  { name: "1.25x speed", value: 1.25 },
  { name: "1.5x speed", value: 1.5 },
  { name: "2x speed", value: 2 },
];

type TimelineEvent = {
  type: "transcript" | "call_start" | "call_end";
  timestamp: number;
  data: TranscriptFragment | InterviewCallMetadata;
};

export const InterviewTranscript: FC<InterviewTranscriptProps> = ({
  loadingState,
  transcriptFragments,
  highlightedTranscriptFragment,
  onClickShare,
  onHighlightFragment,
  calls,
  recordingIdToUrl,
}) => {
  const [failedUrls, setFailedUrls] = useState<Set<string>>(new Set());
  const [playbackSpeed, setPlaybackSpeed] = useState(
    speedOptions.find((option) => option.value === 1)
  );
  const callIdToAudioRef = useRef<{ [key: string]: HTMLAudioElement | null }>(
    {}
  );
  const transcriptFragmentRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const location = useLocation();

  const jumpToFragmentById = (fragmentId: string) => {
    if (transcriptFragmentRefs.current[fragmentId]) {
      onHighlightFragment(fragmentId);
      setTimeout(() => onHighlightFragment(null), 5000);
      transcriptFragmentRefs.current[fragmentId].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Jump to fragment if fragment ID is included as a query param
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fragmentId = queryParams.get("f");

    if (fragmentId && transcriptFragments.length > 0) {
      jumpToFragmentById(fragmentId);
    }
  }, [location.search, transcriptFragments]);

  // Jump to fragment when the highlighted fragment ID changes
  useEffect(() => {
    if (highlightedTranscriptFragment) {
      jumpToFragmentById(highlightedTranscriptFragment);
    }
  }, [highlightedTranscriptFragment]);

  // Update playback speed on audio elements when it changes
  useEffect(() => {
    Object.values(callIdToAudioRef.current).forEach((audio) => {
      if (audio && playbackSpeed) {
        audio.playbackRate = playbackSpeed.value;
      }
    });
  }, [playbackSpeed]);

  const handleAudioError = (url: string) => {
    setFailedUrls((prev) => new Set(prev).add(url));
  };

  const getRecordingUrl = (call: InterviewCallMetadata) => {
    return (
      // Web call case where we store the recording URL directly from retell
      call.recording_url ||
      // Phone call case where we store the twilio recording ID and then use a presigned URL to get the item in AWS
      (call.twilio_recording_id && recordingIdToUrl[call.twilio_recording_id])
    );
  };

  // Add a ref callback to set the initial playback speed when audio elements are created
  const setAudioRef = (id: string, element: HTMLAudioElement | null) => {
    callIdToAudioRef.current[id] = element;
    if (element && playbackSpeed) {
      element.playbackRate = playbackSpeed.value;
    }
  };

  if (loadingState === LoadingStatesEnum.LOADING) {
    return (
      <div className="w-full mt-5 flex flex-col space-y-5">
        <TextSkeleton />
        <TextSkeleton />
      </div>
    );
  }

  if (loadingState === LoadingStatesEnum.ERROR) {
    return (
      <div className="mt-2 text-sm text-orange-600">
        An error occurred while loading the transcript. Please refresh and try
        again.
      </div>
    );
  }

  // Create chronological timeline of transcript fragments and call events
  const events: TimelineEvent[] = transcriptFragments.map((fragment) => ({
    type: "transcript",
    timestamp: new Date(fragment.start_time).getTime(),
    data: fragment,
  }));

  // Add call events
  calls.forEach((call) => {
    if (call.start_time) {
      events.push({
        type: "call_start",
        timestamp: new Date(call.start_time).getTime(),
        data: call,
      });
    }
    if (call.end_time) {
      events.push({
        type: "call_end",
        timestamp: new Date(call.end_time).getTime(),
        data: call,
      });
    }
  });

  // Sort by timestamp
  const timelineEvents: TimelineEvent[] = events.sort(
    (a, b) => a.timestamp - b.timestamp
  );

  const hasRecordings = calls.some((call) => getRecordingUrl(call));

  const renderCallMarker = (
    type: "call_start" | "call_end",
    call: InterviewCallMetadata
  ) => {
    const isStart = type === "call_start";
    const timestamp = new Date(isStart ? call.start_time : call.end_time);
    const formattedTime = timestamp.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });

    return (
      <div>
        <div className="flex items-center justify-between py-2 text-sm">
          <div className="flex items-center space-x-2">
            <PhoneIcon
              className={`h-4 w-4 ${
                isStart ? "text-green-500" : "text-red-500"
              }`}
            />
            <span>{isStart ? "Call started" : "Call ended"}</span>
            <span className="text-gray-500">{formattedTime}</span>
          </div>
        </div>
        {isStart && (
          <div className="my-2">
            {(() => {
              const url = getRecordingUrl(call);
              if (!url || failedUrls.has(url)) return null;

              return (
                <audio
                  ref={(el) => setAudioRef(call.id, el)}
                  controls
                  src={url}
                  onError={() => handleAudioError(url)}
                >
                  Your browser does not support the audio element.
                </audio>
              );
            })()}
          </div>
        )}
        {!isStart && <div className="border-b border-gray-200 my-2" />}
      </div>
    );
  };

  return (
    <>
      {hasRecordings && (
        <div className="w-[150px] mb-4">
          <Select
            options={speedOptions}
            currentSelection={playbackSpeed}
            onChange={({ name }) =>
              setPlaybackSpeed(
                speedOptions.find((option) => option.name === name)
              )
            }
            placeholder="Select speed"
          />
        </div>
      )}
      {timelineEvents.map((event, i) => {
        if (event.type === "transcript") {
          const fragment = event.data as TranscriptFragment;
          return (
            <div
              key={fragment.id}
              ref={(el) => {
                if (el) {
                  transcriptFragmentRefs.current[fragment.id] = el;
                }
              }}
              className={`text-sm text-gray-900 ${
                fragment.is_dynamic_question ? "pl-8" : ""
              }`}
              style={{ scrollMarginTop: STICKY_HEADER_HEIGHT }}
            >
              {fragment.question ? (
                <div className={`text-gray-700 ${i !== 0 ? "mt-4" : ""} mb-2`}>
                  {fragment.question}
                </div>
              ) : null}
              <TranscriptFragmentText
                text={fragment.text_transcript}
                isHighlighted={fragment.id === highlightedTranscriptFragment}
                onClickShare={() => onClickShare(fragment)}
                role={fragment.role}
              />
            </div>
          );
        } else {
          return (
            <div key={`${event.type}-${event.timestamp}`}>
              {renderCallMarker(
                event.type,
                event.data as InterviewCallMetadata
              )}
            </div>
          );
        }
      })}
    </>
  );
};
